<div id="multipleTileContent" class="explore-content" *ngIf="isMulipleTiles">
    <div *ngIf="!isChatBotEnabled || !(isMTL || isLunarLake || isGamingMtl)" id="pcHeading" class="pcheading" [ngClass]="isCreator12thGen|| isCreator13thGen || isStandardEnhancement ? 'stdContentheadingColor' : 'pcheading-non-12th-std'" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="explorePCLabel"></div>
    <div *ngIf="isChatBotEnabled && (isMTL || isLunarLake || isGamingMtl)" class="centre">
        <button type="button" class="btn chatbot-button btn-block" [class.gaming-btn]="isAppGaming" (click)="redirectToChatbot()" >
            <div class="ai_avatar_icon" [class.gaming-icon]="isAppGaming">
                <img src="assets/homepagechaticon.gif" alt="chatbot avatar">
                <input  id="chatbot-input" type="text" class="form-control h-100 typing-effect"  [class.gaming-placeholder]="isAppGaming" [(ngModel)]="placeholderText" [placeholder]="placeholderText" readonly />
            </div>
           
        </button>
    </div>
    
    <div *ngIf="isMTL || isLunarLake">
        <div class="evo-tab-listout">
            <button class="left-arrow" id="left-btn" hidden type="button" (click)="scrollLeft()">
                <img src="assets/images/Chevron_left.svg" alt="left-arrow" />
            </button>   
            <div id="evoTabScroll" class="d-flex evoTabScroll" (scroll)="scroll()" >
                <div *ngFor="let tab of slides, let i=index"  (scroll)="onScroll($event)">
                    <button class="btn evoTabSpacing" [ngClass]="index==i ? 'active' : 'evoTab' " (click)="showContent(i)" [class.th-width]=" isThaiLang || isIndLang " >
                        <div class="evoTabHeading" [innerHTML]="tab.tr_tileTitle"></div>
                    </button>
                </div>
               
            </div>
            <button class="right-arrow2" id="right-btn" type="button" (click)="scrollRight()">
                <img src="assets/images/chevron_right_ftag_white.svg" alt="right-arrow" />
            </button>  
                                        
        </div>

        <ngx-slick-carousel class="carousel"
        #slickModal="slick-carousel" [config]="slideConfig" (beforeChange)="onScroll($event)" [class.intelInsideCarousel]="isIntelInside">
            <div id="carouselTile" ngxSlickItem *ngFor="let slide of evoSlide" class="pc-items-box slide aiIpos-box item-box-spacing" 
            >  <!-- IJR-1735 --> 
                <div id="tileBadgeImage" (click)="open(content, slide.tileVideoUrl)"><img src="{{slide.tileBadgeUrl}}" alt="tile image">
                    <div id="sliderTileContent" class="slider-image-content" [ngClass]="isAppGaming ? 'gaming-background' : 'standard-background'"> <!--IJR-2925-->
                        <p  [ngClass]="[isStandardEnhancement ? 'std12_text' : 'pcheading-non-12th-std', isSpanishLang ? 'spanish-text' : '']" [class.black-label-color]="isBlackColorLabel"  [class.std13thgen_text]="is13thgenStd" [innerHTML]="slide.tr_title"></p>
                        <img *ngIf="clickable(slide.tileVideoUrl)" class="arrowclass" [ngClass]="isAppGaming ? 'right-arrow' : 'right-arrow-black'" alt="right-arrow">
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
    <ngx-slick-carousel *ngIf="!isMTL && !isLunarLake" class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (beforeChange)="onScroll($event)" [class.intelInsideCarousel]="isIntelInside">
        <div id="carouselTile" ngxSlickItem *ngFor="let slide of slides" class="pc-items-box slide"
        >  <!-- IJR-1735 -->
            <div id="tileBadgeImage" (click)="gotoDetailPage(slide.tileUrlName)"> <img src="{{slide.tileBadgeUrl}}" alt="tile image">
                <div id="sliderTileContent" class="slider-image-content" [ngClass]="isAppGaming ? 'gaming-background' : 'standard-background'"> <!--IJR-2925-->
                    <p [ngClass]="isStandardEnhancement ? 'std12_text' : 'pcheading-non-12th-std'" [class.black-label-color]="isBlackColorLabel"  [class.std13thgen_text]="is13thgenStd" [innerHTML]="slide.tr_tileTitle"></p>
                    <img class="arrowclass" [ngClass]="isAppGaming ? 'right-arrow' : 'right-arrow-black'" alt="right-arrow">
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div>

<div id="singleTileContent" class="explore-content" *ngIf="!isMulipleTiles">
    <div id="singleTilePcHeading" class="pcheading" [ngClass]="isCreator12thGen||isCreator13thGen || isStandardEnhancement ? 'stdContentheadingColor' : 'pcheading-non-12th-std'" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="explorePCLabel"></div>
    <div id="singleTileSlider" *ngFor="let slide of slides"> 
        <div id="tileImage" (click)="gotoDetailPage(slide.tileUrlName)"> 
            <img class="imgWidth" src="{{slide.tileBadgeUrl}}" alt="tile image">
            <div id="sliderTileTitle" class="slider-image-content singleBadge-text" [ngClass]="isAppGaming ? 'gaming-background' : 'standard-background'">
                <p [ngClass]="isStandardEnhancement ? 'std12_text' : 'pcheading-non-12th-std'" [class.black-label-color]="isBlackColorLabel" [innerHTML]="slide.tr_tileTitle"></p>
                <img class="arrowclass" [ngClass]="isAppGaming ? 'right-arrow' : 'right-arrow-black'" alt="right-arrow">
            </div>
        </div>
    </div>
</div>

<div id="factTagListout" [ngClass]="isMTL || isLunarLake || isGamingMtl ? 'fact-tag-listout-aiIpos-padding' : 'fact-tag-listout-padding'" >
    <div id="pcSpecsLabel" [class.intelOne-display]="setFontIntelDisplayOne" [class.device-Specs]="isMTL || isLunarLake || isGamingMtl" [class.clear-font-bold]="!setFontIntelDisplayOne" class="pc-assets-specification" [ngClass]="(isCreator12thGen||isCreator13thGen || isStandardEnhancement) ? 'stdContentheadingColor' : 'pcheading-non-12th-std'" [innerHTML]="pcSpecsLabel"></div>
    <app-pc-specs></app-pc-specs>
</div>
<ng-template #content let-modal >
    <div class="close-video" (click)="modal.dismiss('Cross click')">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>    
    <video id="tileVideo" class="mtlVideo" width="400" poster= "assets/images/homePage-one-poster.png" preload="auto" playsinline controlsList="nodownload nofullscreen noplaybackrate" 
    disablePictureInPicture (ended)="showPlayIcon()" controls #videoPlayer>
        <source src="{{videoUrl}}" type="video/mp4" >
    </video>
    <img id="videoIcon" class="play-icon2" #videoIcon (click)="toggleVideo()" alt="play-Icon"/>
</ng-template>



