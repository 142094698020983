import { Injectable, OnDestroy } from '@angular/core';
import { ResetBotService } from './resetBot.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChatbotApiService } from '../../core/services/chatbot/chatbot.service';
import { environment } from '../../../environments/environment';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';
import { GoogleAnalyticsService } from '../../core/services/google-analytics-service/google-analytics.service';
import { GlobalConstants } from '../../common/constants/global.constant';
import { ChatbotStatusService } from './chatbot-status.service';
import { PcSpecsService } from '../../modules/details-page/services/pc-specs/pc-specs.service';
import { filter } from 'rxjs/operators';
import { Router, Event, RouterEvent } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class WrapperService implements OnDestroy {
  ngUnsubscribe = new Subject<boolean>();
  static paFlag = false;
  static retailerFlag: any;

  constructor(private resetLocale: ResetBotService,
    private chatbotApiService: ChatbotApiService,
    private qrCodeService: QrCodeService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private chatData: ChatbotStatusService,
    private pcSpecsService: PcSpecsService,
    private router: Router
  ) {
    this.autoMinimize();
    WrapperService.paFlag = true;

    window.dispatchEvent(new CustomEvent('IPOS_EVENT', { detail: "App running" }));

    if (WrapperService.paFlag) {
      this.chatbotApiService.getChatbotData();
      window.dispatchEvent(new CustomEvent('SEND_LOCALE_DATA_EVENT', { detail: this.chatbotApiService.chatbot }));
    }

    window.addEventListener('URL_EVENT', (evt: any) => {
      window.dispatchEvent(new CustomEvent('ENV_EVENT', { detail: environment.chatbotURL }));
      window.dispatchEvent(new CustomEvent('SRTOFFLINE_EVENT', { detail: WrapperService.retailerFlag }));
    });
 window.addEventListener('URL_STUDIO', (evt: any) => {
window.dispatchEvent(new CustomEvent('ENV_EVENT1', { detail: environment.chatbotStudioURL }));      
        });

    window.addEventListener('LIVE_URL_EVENT', (evt: any) => {
      window.dispatchEvent(new CustomEvent('ENV_EVENT', { detail: environment.chatbotURL }));
    });

    window.addEventListener('GET_LOCALE_DATA_EVENT', (evt: any) => {
      if (WrapperService.paFlag)
        this.chatbotApiService.getChatbotData();
      window.dispatchEvent(new CustomEvent('SEND_LOCALE_DATA_EVENT', { detail: this.chatbotApiService.chatbot }));
    });

    if (WrapperService.paFlag) {
      window.dispatchEvent(new CustomEvent('QR_CODE_EVENT', { detail: this.qrCodeService.getQrData() }));

      window.addEventListener('GA_RESET_CHAT_EVENT', (evt: any) => {
        this.googleAnalyticsService.resetChat();
      });

      window.addEventListener('GA_CLOSE_CHAT_EVENT', (evt: any) => {
        this.googleAnalyticsService.closeBot();
      });

      window.addEventListener('GA_USER_MESSAGE_EVENT', (evt: any) => {
        this.googleAnalyticsService.userMessage();
      });

      window.addEventListener('GA_ACCEPT_AGENT_EVENT', (evt: any) => {
        this.googleAnalyticsService.chatAcceptedByAgent();
      });

      window.addEventListener('GA_OPEN_BOT_EVENT', (evt: any) => {
        this.googleAnalyticsService.openBot();
      });

      window.addEventListener('SET_CHATBOT_EVENT', (evt: any) => {
        this.chatData.setChatbot(false, true);
        // console.log("SET_CHATBOT_EVENT");
      });
      window.addEventListener('SET_CHATBOT_EVENT_1', (evt: any) => {
        this.chatData.setChatbot(true, false);
        // console.log("SET_CHATBOT_EVENT_1");
      });
      window.addEventListener('SET_CHATBOT_EVENT_2', (evt: any) => {
        this.chatData.setChatbot(true, true);
        // console.log("SET_CHATBOT_EVENT_2");
      });

      window.addEventListener('SET_CHATBOT_INACTIVE_EVENT', (evt: any) => {
        this.chatData.setChatbotActive(false);
        // console.log("SET_CHATBOT_INACTIVE_EVENT");
      });
      window.addEventListener('SET_CHATBOT_ACTIVE_EVENT', (evt: any) => {
        this.chatData.setChatbotActive(true);
        // console.log("SET_CHATBOT_ACTIVE_EVENT");
      });

      window.addEventListener('GA_FALLBACK_EVENT', (evt: any) => {
        this.googleAnalyticsService.fallBack();
      });

      window.addEventListener('GA_TRANSFER_AGENT_EVENT', (evt: any) => {
        this.googleAnalyticsService.transferToAgent();
      });

    
      window.addEventListener('GA_BOT_MESSAGE_EVENT', (evt: any) => {
        this.googleAnalyticsService.botMessage();
      });

      window.addEventListener('GA_COMMON_PHRASE_EVENT', (evt: any) => {
        this.googleAnalyticsService.commonPhrase(evt.detail);
      });
      window.addEventListener('PACKAGE_EVENT', (evt: any) => {
        window.dispatchEvent(new CustomEvent('SEND_PACKAGE_EVENT', { detail:this.getIposType() }));
      });
      window.addEventListener('GA_SRT_EVENT', (evt: any) => {
        if (evt.detail.value.toLowerCase() == "forward" || evt.detail.value.toLowerCase() == "back")
          window.dispatchEvent(new CustomEvent('MT_EVENT', { detail: "manage timer" }));
        this.googleAnalyticsService.sendGaEvent(evt.detail.trigger, evt.detail.dataPoint, evt.detail.value, evt.detail.params);
      });
   

      window.addEventListener('SPEC_EVENT', (evt: any) => {
        window.dispatchEvent(new CustomEvent('MODEL_EVENT', { detail: this.qrCodeService?.qrInput?.DeviceName }));
        window.dispatchEvent(new CustomEvent('RETAILER_EVENT', { detail: this.getRetailerName() }));
        window.dispatchEvent(new CustomEvent('LANGUAGE_EVENT', { detail: this.getLanguage() }));
        window.dispatchEvent(new CustomEvent('MANUFACTURER_EVENT', { detail: this.qrCodeService.getDisplayText("manufacturer") }));
        window.dispatchEvent(new CustomEvent('PROCESSOR_EVENT', { detail: this.qrCodeService.getDisplayText("processor") }));
        window.dispatchEvent(new CustomEvent('WIFI_EVENT', { detail: this.qrCodeService.getDisplayText("wifi") }));
        window.dispatchEvent(new CustomEvent('MEMORY_EVENT', { detail: this.qrCodeService.getDisplayText("memory") }));
        window.dispatchEvent(new CustomEvent('BATTERY_EVENT', { detail: this.qrCodeService.getDisplayText("battery") }));
        window.dispatchEvent(new CustomEvent('DISPLAY_EVENT', { detail: this.qrCodeService.getDisplayText("display") }));
        window.dispatchEvent(new CustomEvent('THUNDERBOLT_EVENT', { detail: this.qrCodeService.getDisplayText("thunderbolt") }));
        window.dispatchEvent(new CustomEvent('STORAGE_EVENT', { detail: this.qrCodeService.getDisplayText("storage") }));
        window.dispatchEvent(new CustomEvent('GRAPHIC_EVENT', { detail: this.qrCodeService.getDisplayText("graphics") }));
        window.dispatchEvent(new CustomEvent('OS_EVENT', { detail: this.qrCodeService.getDisplayText("OS") }));
        window.dispatchEvent(new CustomEvent('TOUCHSCREEN_EVENT', { detail: this.qrCodeService.getDisplayText(GlobalConstants.TOUCH_SCREEN) }));
        window.dispatchEvent(new CustomEvent('VRAM_EVENT', { detail: this.qrCodeService.getDisplayText("vram") }));
        const clockSpeedValue = this.qrCodeService.getDisplayText("clockspeed") ? this.qrCodeService.transformDecimal(this.qrCodeService.getDisplayText("clockspeed")) : this.qrCodeService.getDisplayText("clockspeed");
        window.dispatchEvent(new CustomEvent('CLOCKSPEED_EVENT', { detail:clockSpeedValue}));
        window.dispatchEvent(new CustomEvent('FPS_EVENT', { detail: this.qrCodeService.getDisplayText("fps") }));
        window.dispatchEvent(new CustomEvent('STOREID_EVENT', { detail: this.qrCodeService?.qrInput?.StoreId }));
        window.dispatchEvent(new CustomEvent('AI_IPOS_EVENT', { detail: this.qrCodeService.isAiIpos()}));
      });
    }

    window.addEventListener('spec_page', (evt: any) => {
      this.routeToChatbotDetailePage(evt.detail);
    });

    this.router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      if(e.url.includes('/specs')){
        window.dispatchEvent(new CustomEvent('DETAILPAGE_EVENT', { detail: "detail_page"}));
      }
      if (e.url !== '/mob-content') {
        window.dispatchEvent(new CustomEvent('FCHATTOP_EVENT', { detail: 18 }));        
      }
      else {
        window.dispatchEvent(new CustomEvent('FCHATTOP_EVENT'));   
      }
      window.dispatchEvent(new CustomEvent('TABANIMATE_EVENT'));     
    });

    this.resetLocale.$resetBotSync
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isLocaleChanged => {
        window.dispatchEvent(new CustomEvent('RESET_EVENT', { detail: "reset component" }));
      });

    window.addEventListener('OPENCHATBOT_EVENT', (evt: any) => {
        // console.log("offline srt---",evt.detail);
      WrapperService.retailerFlag = evt.detail;
    });

    window.addEventListener('OPEN_AI_BOT', (evt: any) => {
      window.dispatchEvent(new CustomEvent('OPEN_IPOS_AI'));
    });
  }
  public autoMinimize() {
    this.chatData.$chatbotSync
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(chatStatus => {
        // console.log("wrapper Inside minimize-----");     
        window.dispatchEvent(new CustomEvent('CHAT_STATUS_EVENT', { detail: chatStatus }));
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getLanguage() {
    console.log("Get BOT Language: ", this.qrCodeService?.qrInput?.Language);
    return this.qrCodeService?.qrInput?.Language;
  }
  

  //Navigate to detail pages 
  routeToChatbotDetailePage(pageName) {
    this.chatData.minimizeChat();
    this.pcSpecsService.setCurrentHighlightFactTag(pageName);
    this.pcSpecsService.gotoDetailPage(pageName, GlobalConstants.FACTTAG);
  }

  //Get the Retailer Name
  getRetailerName() {
    return this.qrCodeService?.qrInput?.RetailerName;
  }

  getIposType() {
    const selectedType = this.qrCodeService.qrInput.ContentType;
    console.log("IPOS TYPE:",selectedType)
    if (selectedType.toLowerCase().includes('s') ) {
        return GlobalConstants.STANDARD.toLowerCase();
    } else if(selectedType.toLowerCase().includes('g') ) {
      return GlobalConstants.GAMING.toLowerCase();
    }      
    else if(selectedType.toLowerCase().includes('evo') ) {
      return GlobalConstants.EVO.toLowerCase();

    }  return null;
  }
  

  
}