<div id="factTagList" class="fact-tag-listout">    
    <div id="pcSpecsScroll" class="d-flex mt-3 pcSpecsScroll" (scroll)="onScroll($event)">
        <ng-container *ngFor="let item of pcSpcesData; let i = index" >
            <div id="psSpecsInfo" *ngIf="!(isHomePage && isAiIpos)" class="fact-tag-box text-center bd-highlight tag_{{item.name}}"
            (click)="highlightFacttag !== item.name && gotoDetailPage(item.name)" [ngClass]="{'bd-highlight':item.name === highlightFacttag,'standard-background': isStdGen,'gaming-background': !isStdGen || is13GenCreator}" > <!-- IJR-2707 fix -->
                <img class="{{item.name}}_img" src="{{item?.image ? item.image : item.imageURL}}" alt="img">
                <p class="card-title" [ngClass]="isStdGen ? 'std-font-color' : 'gaming-font-color'" [innerHTML]="item.homeTitle"></p>
            </div>

            <div id="deviceSpecsInfo" *ngIf="isHomePage && isAiIpos " class="fact-tag-box2  bd-highlight tag_{{item.name}}"
            (click)="highlightFacttag !== item.name && gotoDetailPage(item.name)" [ngClass]="{'bd-highlight':item.name === highlightFacttag,'standard-background': isStdGen,'gaming-background': !isStdGen || is13GenCreator}" > <!-- IJR-2707 fix -->
            <div class="card-title deviceSpecs-title " [ngClass]="isStdGen ? 'std-font-color' : 'gaming-font'" [innerHTML]="item.homeTitle"></div>    
            <div class="slider-image-content">
                <img class="{{item.name}}_img ftag_img" src="{{item?.image ? item.image : item.imageURL}}" alt="img">
                <p  [ngClass]="isStdGen ? 'std-font-color' : 'gaming-font-color'" [class.rtllang]="isRTL && isAiIpos" [innerHTML]="item.description"></p>
                <img class="arrowclass" [ngClass]="{'right-arrow-black': isStdGen, 'right-arrow': !isStdGen, 'rtl-arrow': isRTL}" alt="right-arrow">
            </div>               
            </div>
        </ng-container>
    </div>
</div>