import { Component,OnInit,ViewChild,TemplateRef} from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { TilesService } from '../../details-page/services/tiles/tiles.service';
import { TilesDetails, TitleArray } from '../../../core/interfaces/library-v2-data.i';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { MessageService } from '../../../core/services/message-service/message.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { PcSpecsService } from '../../details-page/services/pc-specs/pc-specs.service';
import { ResetBotService } from '../../../chatbot/services/resetBot.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QrConstants } from '../../../core/services/qr-input-service/qr.constants';

@Component({
  selector: 'app-tile-content',
  templateUrl: './tile-content.component.html',
  styleUrls: ['./tile-content.component.scss']
})
export class TileContentComponent implements OnInit {
  explorePCLabel: string;
  pcSpecsLabel: string;
  index: number= 0;
  slides: TilesDetails[];
  evoSlide: TitleArray[];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "infinite": false, "dots": true, "arrows": false, "variableWidth": true, "rtl": false};
  setFontIntelDisplayOne: boolean;
  homePageCount = 0;
  previousPage = [];
  isStandardEnhancement = false;
  isMTL= false;
  isEvoMTL= false;
  isAppGaming: boolean;
  contentType: string;
  isBlackColorLabel = false;
  isCreator12thGen = false;
  isCreator13thGen = false;
  isMulipleTiles = false;
  is13thgenStd = false;
  isIntelInside= false;
  displayStyle = "none";
  videoUrl: string;
  closeResult = '';
  isSpanishLang = false;
  isThaiLang = false;
  isIndLang = false;
  playVideo = false;
  productivityUnisonVideoLangs = ["de","es-mx","fr","id","it","ja","ko","pl","pt-br","th","tr","vi","zh-cn","zh-tw",];
  productivityUnisonSupportedLangs = ["en-us","de","es-mx","fr","id","it","ja","ko","pl","pt-br","th","tr","vi","zh-cn","zh-tw","ru","sv","uk"];
  isLunarLake = false;
  isChatBotEnabled = false;
  placeholderText : string;
  isGamingMtl =false;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  

  constructor(private libraryV2DataService: LibraryV2DataService,
              private tilesService: TilesService,
              private qrService: QrCodeService,
              private messageService: MessageService,
              private pcSpecsService: PcSpecsService,
              private resetBot: ResetBotService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // for testing
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // for testing
    // IJR-2344 fix
    const language = this.qrService.qrInput.Language;
    this.contentType = this.qrService.qrInput.ContentType;
    this.placeholderText = this.libraryV2DataService.getStaticText('tr_chatbotPlaceholder_text');
    if (language === 'ar-sa' || language === 'he') {
      this.slideConfig.rtl = true;
    } 
    this.isSpanishLang = language === 'es-mx';
    this.isThaiLang = language === 'th';
    this.isIndLang = language === 'id';
    const processorBadge = this.qrService?.qrInput?.ProcessorBadge;
    const is12thGenVPro = GlobalConstants.VPRO_12THGEN_BADGES?.includes(processorBadge);
    this.isIntelInside = this.contentType === GlobalConstants.STANDARDGEN12 && GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge); //IIT2-6964
    this.isAppGaming = this.contentType[0] === GlobalConstants.GAMINGGEN12[0] ||
      this.contentType === GlobalConstants.CREATORGEN11 || this.contentType === GlobalConstants.CREATORGEN12|| this.contentType === GlobalConstants.CREATORGEN13 ? true : false;
    this.isBlackColorLabel = this.contentType === GlobalConstants.EVO || this.contentType === GlobalConstants.STANDARDGEN11 ||
    (this.contentType === GlobalConstants.STANDARDGEN12 && is12thGenVPro) || this.isIntelInside || (this.contentType === GlobalConstants.STANDARDGEN14 && (GlobalConstants.STD_MTL_BADGES?.includes(processorBadge) || GlobalConstants.STD_LNL_BADGES?.includes(processorBadge)));
    this.isMTL= GlobalConstants.EVO_MTL_BADGES?.includes(processorBadge) || GlobalConstants.STD_MTL_BADGES?.includes(processorBadge);
    this.isGamingMtl = GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(processorBadge);
    this.isLunarLake = GlobalConstants.LUNAR_BADGES?.includes(processorBadge);
    this.explorePCLabel = this.isMTL? this.libraryV2DataService.getStaticText('tr_mtlPC_text') : this.libraryV2DataService.getStaticText('tr_explorePC_text');  
    this.pcSpecsLabel = this.isMTL || this.isLunarLake || this.isGamingMtl ? this.libraryV2DataService.getStaticText('tr_deviceSpecs_text') : this.libraryV2DataService.getStaticText('tr_PCSpecs_text');
    this.isEvoMTL= GlobalConstants.EVO_MTL_BADGES?.includes(processorBadge);
    this.isChatBotEnabled =  this.qrService?.qrInput?.IsChatbotEnabled?.toLowerCase().includes('s') ||  this.qrService?.qrInput?.IsChatbotEnabled?.toLowerCase().includes('y')||  this.qrService?.qrInput?.IsChatbotEnabled?.toLowerCase().includes('a');
    this.getTilesData();
    if(this.isMTL || this.isLunarLake) {
      this.showContent(this.index);
    }
    this.setFontIntelDisplayOne = this.qrService.setenusLang();
    this.messageService.getHomePageCount.subscribe(data => {
      this.homePageCount = data;
    });
    this.messageService.getPreviousPage.subscribe(data => {
      this.previousPage = data;
    });
    if ((this.contentType === GlobalConstants.STANDARDGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 || this.contentType === GlobalConstants.STANDARDGEN14)) {
      this.isStandardEnhancement = true;
    }
    if (this.contentType === GlobalConstants.CREATORGEN12) {
      this.isCreator12thGen = true;
    }
    if (this.contentType === GlobalConstants.CREATORGEN13) {
      this.isCreator13thGen = true;
    }
    this.is13thgenStd = this.qrService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN13; // IIT2-8838
  }

  getTilesData(): void {
    this.slides = this.tilesService.getTiles();    
    this.isMulipleTiles = this.slides?.length > 1;    
  }


  gotoDetailPage(facttagValue): void {
    this.pcSpecsService.setCurrentHighlightFactTag(facttagValue);
    this.pcSpecsService.gotoDetailPage(facttagValue, GlobalConstants.TILE);
  }

  onScroll(event): void{
    this.resetBot.resetBotIcon(true);
  } 
  showContent(index: number){
    if(this.slickModal && this.index!=index){
      this.slickModal.unslick();
    }
    this.index=index;  
    this.evoSlide = this.slides[index]?.tileDetails?.titleArray;
    this.evoSlide.forEach((item, key) => {
      item.tileVideoUrl = item.tileVideoUrl.replace(GlobalConstants.XX,this.qrService.qrInput.Language);
    })
        
    if(this.evoSlide[2]?.detailPageName === "yourworld" && this.productivityUnisonVideoLangs.includes(this.qrService.qrInput.Language)){
      this.evoSlide[2].tileVideoUrl = "assets/lang/" + this.qrService.qrInput.Language + "/std/videos/yourworld.mp4";
    } 
    if((this.evoSlide[0]?.detailPageName === "unison" && this.evoSlide?.length === 1) || 
    ((QrConstants.ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.GraphicsBadge)===-1 && 
    this.qrService?.qrInput?.GraphicsBadge[0] !== 'A') && (QrConstants.ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.SecondaryGraphicsBadge)===-1 && 
    this.qrService?.qrInput?.SecondaryGraphicsBadge !== 'A') &&  this.qrService?.qrInput?.GraphicsBadge !== QrConstants.BADGE_GIN012 && this.qrService?.qrInput?.SecondaryGraphicsBadge !== QrConstants.BADGE_GIN012 && this.evoSlide[0]?.detailPageName.includes('smooth'))) {
      setTimeout(() => {
        const carouselDot = document.querySelectorAll('.slick-dots')[0]?.setAttribute('hidden','');
      }, 200);
    }
    const productivityIndex =  this.slides.length === 5 ? 3 : 2;
    if(!this.productivityUnisonSupportedLangs.includes(this.qrService.qrInput.Language) && index == productivityIndex){
      this.evoSlide = this.evoSlide.filter(item => item.detailPageName !== 'yourworld');      
    }
    
    if(QrConstants.ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.GraphicsBadge)===-1 && this.qrService?.qrInput?.GraphicsBadge[0] !== 'A' && (QrConstants.ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.SecondaryGraphicsBadge)===-1 && 
    this.qrService?.qrInput?.SecondaryGraphicsBadge !== 'A') &&  this.qrService?.qrInput?.GraphicsBadge !== QrConstants.BADGE_GIN012 && this.qrService?.qrInput?.SecondaryGraphicsBadge !== QrConstants.BADGE_GIN012){
      if(GlobalConstants.STD_MTL_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge) && index == 3){
        this.evoSlide = this.evoSlide.filter(item => item.detailPageName !== 'fastimmersive');
      }
      if(GlobalConstants.LUNAR_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge)&& index==1){
        this.evoSlide = this.evoSlide.filter(item => item.detailPageName !== 'ultraimmersive');
      }
      const slidesLen = this.slides.length === 5 ? 2 : 1;
      if((this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT158 || this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT159) && index == slidesLen){
        this.evoSlide = this.evoSlide.filter(item => item.detailPageName !== 'videoplayback')
      }
    }
  }
  open(content: TemplateRef<any>,url:string) {
    
    
    if(url==="" || url === undefined){
     this.gotoDetailPage(this.evoSlide[this.index].detailPageName);
      
    }
    else if(!(url.includes("images"))){
      this.videoUrl= url; 
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.playVideo=false;
        },
      );
      this.toggleVideo();  
    }
  }
 
  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
  toggleVideo(){    
    const videoplayer= document.getElementById('tileVideo') as HTMLVideoElement;
    const video = this.playVideo ? videoplayer.pause() : videoplayer.play();
    this.playVideo = !this.playVideo;
    this.qrService.changeVideoPlayingStatus(this.playVideo); //IIT2-3585
    return video;

  }
  showPlayIcon(){
    const videoplayer= document.getElementById('tileVideo') as HTMLVideoElement;
    this.playVideo = !this.playVideo;
    this.qrService.changeVideoPlayingStatus(this.playVideo); //IIT2-3585
  }
  clickable(url: string){
    if(url && url.includes("images")){
      return false;
    }
    else{
      return true;
    }
  }
  scrollLeft(){
    const scrollContainer = document.getElementById('evoTabScroll') as HTMLElement;
    const leftBtn = document.getElementById('left-btn') as HTMLElement;
    const rightBtn = document.getElementById('right-btn') as HTMLElement;
    rightBtn.hidden= false;
    const scroll = scrollContainer.scrollLeft;
    scrollContainer.scrollBy({left: -scroll, behavior:'smooth'});
    if(scrollContainer.scrollLeft <= 0){      
      leftBtn.hidden= true;     
    }
  }

  scrollRight(){
    const scrollContainer = document.getElementById('evoTabScroll') as HTMLElement;
    const leftBtn = document.getElementById('left-btn') as HTMLElement;
    const rightBtn = document.getElementById('right-btn') as HTMLElement;
    leftBtn.hidden= false;
    scrollContainer.scrollBy({left: scrollContainer.scrollWidth - scrollContainer.clientWidth, behavior:'smooth'});
    if(scrollContainer.scrollLeft +scrollContainer.clientWidth >= scrollContainer.scrollWidth){      
      rightBtn.hidden= true;     
    }
  }    
  redirectToChatbot(){
    window.dispatchEvent(new CustomEvent('OPEN_AI_BOT',{ detail: "OPEN_CHATBOT"}));    
  }
  scroll(){
    const scrollContainer = document.getElementById('evoTabScroll') as HTMLElement;
    const leftBtn = document.getElementById('left-btn') as HTMLElement;
    const rightBtn = document.getElementById('right-btn') as HTMLElement;  
    if(scrollContainer.scrollLeft <= 0){      
      leftBtn.hidden= true; 
      rightBtn.hidden= false;    
    } 
    if(scrollContainer.scrollLeft +scrollContainer.clientWidth >= scrollContainer.scrollWidth){      
      rightBtn.hidden= true; 
      leftBtn.hidden= false;    
    }
  }
  
}
